import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { ReportTimeline } from '../../components/library'
import theme from '../../theme'

const data =  [
    {
        time: '2021-06-27T17:20:50.923-07:00',
        location: 'Playground',
        trigger: 'Pollen'
    },
    {
        time: '2021-06-27T10:10:50.923-07:00',
        location: 'Playground',
        trigger: 'Pollen'
    },
    {
        time: '2021-06-27T14:19:50.923-07:00',
        location: 'Mall',
    },
]


function section() {
    return (
        <LibraryWrapper>
            <ReportTimeline 
                data={data} 
                edit 
                onDelete={(e, iso, new_data) => {
                    console.log('DELETED AN ITEM', iso, new_data)
                }}
                onAdd={(e, item, new_data) => {
                    console.log('ADDED AN ITEM', item, new_data);
                }}
                onEdit={(e, item, new_data) => {
                    console.log('EDITED AN ITEM', item, new_data);
                }}
            />
        </LibraryWrapper>
    )
}
export default section
